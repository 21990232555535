import { Injectable } from '@angular/core';
import { addToDate } from '@next-insurance/date';
import { select, Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { EnvConfig } from '../../../environments/env.config';
import { environment } from '../../../environments/environment';
import { BusinessIdService } from '../../business/business-id.service';
import { AppState } from '../../store';
import { TAB_ID } from '../config/global-session-storage-items.config';
import { pollUntil } from '../helpers/custom-operators';
import { FullstoryEvent } from '../models/fullstory-event.enum';
import { FullStoryUserData } from '../models/fullstory-user-data.model';
import { UserType } from '../models/user-type.enum';
import { coreSelectors } from '../store/core.selectors';

@Injectable({
  providedIn: 'root',
})
export class FullStoryService {
  private readonly isEnabled: boolean;
  private businessId: string;

  constructor(
    private store: Store<AppState>,
    private cookieService: CookieService,
    private businessIdService: BusinessIdService,
  ) {
    this.isEnabled = EnvConfig.isProduction() && Math.random() < 0.5; // Record 50% randomly of sessions

    window._fs_ready = () => {
      const sessionUrl = window.FS.getCurrentSessionURL();
      if (Sentry.setTag && sessionUrl) {
        Sentry.setTag('fullstory', sessionUrl);
        this.setFullStorySessionUrlCookie(sessionUrl);
      }
    };
  }

  init(trackingId: string): void {
    if (this.isEnabled) {
      this.waitForFullStory().subscribe(() => {
        this.setUserVars({ trackingId });
        this.setBusinessIdUserVar();
      });
    }
  }

  fireEvent(eventName: FullstoryEvent | string, eventOptions?: any): void {
    let options = eventOptions;
    if (this.isEnabled && eventName && window.FS) {
      this.store.pipe(select(coreSelectors.getUserType), first()).subscribe((userType: UserType) => {
        options = {
          ...eventOptions,
          businessId: this.businessId,
          userType,
          tabId: sessionStorage.getItem(TAB_ID),
        };
      });
      window.FS.event(eventName, options);
    }
  }

  private setUserVars(userData: FullStoryUserData): void {
    if (this.isEnabled && userData) {
      window.FS.setUserVars(this.sanitizeAttributes(userData));
    }
  }

  private waitForFullStory(): Observable<boolean> {
    return pollUntil(() => {
      return window.FS && !!window.FS.setUserVars;
    });
  }

  private sanitizeAttributes(attributes: FullStoryUserData): Record<string, unknown> {
    return Object.entries(attributes).reduce((acc: Record<string, unknown>, [attributeName, attributeValue]) => {
      let suffix = '';
      const isNumber = typeof attributeValue === 'number' && !Number.isNaN(attributeValue) && Number.isFinite(attributeValue);
      const isBoolean = typeof attributeValue === 'boolean';
      const isDate = attributeValue instanceof Date;
      const isString = typeof attributeValue === 'string';

      if (isNumber) {
        suffix = Number.isSafeInteger(attributeValue) ? '_int' : '_real';
      }

      if (isBoolean) {
        suffix = '_bool';
      }
      if (isDate) {
        suffix = '_date';
      }
      if (isString) {
        suffix = '_str';
      }

      acc[attributeName + suffix] = attributeValue;
      return acc;
    }, {});
  }

  private setBusinessIdUserVar(): void {
    this.businessIdService
      .getBusinessId$()
      .pipe(filter((businessId) => !!businessId))
      .subscribe((businessId: string) => {
        this.businessId = businessId;
        this.setUserVars({ businessId });
      });
  }

  private setFullStorySessionUrlCookie(sessionUrl: string): void {
    if (environment.fullStorySessionUrlCookieName) {
      const expiryDate = addToDate(new Date(), { hours: 4 });
      this.cookieService.set(environment.fullStorySessionUrlCookieName, sessionUrl, expiryDate, '/', `.${environment.tld}`);
    }
  }
}
