import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { LOCAL_STORAGE } from '../core/tokens/local-storage.token';

@Injectable({
  providedIn: 'root',
})
export class BusinessIdService {
  private readonly LAST_SELECTED_BUSINESS_KEY = 'lastSelectedBusinessId';
  private localStorage: Storage = inject(LOCAL_STORAGE);
  private currentBusinessIdSubject$ = new BehaviorSubject<string>(undefined);
  private accountBusinessIdsSubject$ = new BehaviorSubject<string[]>([]);
  private businessId$ = this.currentBusinessIdSubject$.asObservable();

  getLastSelectedBusinessId(): string {
    return this.localStorage.getItem(this.LAST_SELECTED_BUSINESS_KEY);
  }

  setBusinessId(businessId: string): void {
    this.localStorage.setItem(this.LAST_SELECTED_BUSINESS_KEY, businessId);
    this.currentBusinessIdSubject$.next(businessId);
  }

  removeBusinessId(): void {
    this.currentBusinessIdSubject$.next('');
  }

  getBusinessId(): string {
    return this.currentBusinessIdSubject$.getValue();
  }

  getBusinessId$(): Observable<string> {
    return this.businessId$;
  }

  getAccountBusinessIds(): string[] {
    return this.accountBusinessIdsSubject$.getValue();
  }

  setAccountBusinessIds(businessIds: string[]): void {
    this.accountBusinessIdsSubject$.next(businessIds);
  }
}
