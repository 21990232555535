import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Event } from '@angular/router';
import { ButtonType } from '@next-insurance/ni-material/enums';
import { Store } from '@ngrx/store';
import { OverlayPanel } from 'primeng/overlaypanel';
import { combineLatest, of, Subscription } from 'rxjs';
import { filter, switchMap, takeWhile, tap } from 'rxjs/operators';

import { PaymentDetailsPerPolicy, PolicyPaymentDetails } from '../../../payment/models/payment-details-per-policy.model';
import { CreditCardPaymentMethod } from '../../../payment/models/payment-method-details.model';
import { PaymentDataService } from '../../../payment/payment.data.service';
import { PaymentService } from '../../../payment/payment.service';
import { paymentSelectors } from '../../../payment/store/payment.selectors';
import { policiesSelectors } from '../../../policies/store/policies.selectors';
import { ToastType } from '../../../shared/components/toast/models/toast-type.enum';
import { catchErrorAndLog } from '../../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../../store';
import { MobileAppEventId } from '../../models/mobile-app-send-event.model';
import { BannersTrackingService } from '../../services/banners-tracking.service';
import { FailedPaymentBannerTrackingService } from '../../services/failed-payment-banner-tracking.service';
import { MobileAppEventsService } from '../../services/mobile-app-events.service';
import { ToastService } from '../../services/toast.service';
import { SESSION_STORAGE } from '../../tokens/session-storage.token';

@Component({
  selector: 'ni-credit-card-failed-payment-alert-banner',
  templateUrl: './credit-card-failed-payment-alert-banner.component.html',
  styleUrls: ['./credit-card-failed-payment-alert-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardFailedPaymentAlertBannerComponent implements OnInit, OnDestroy {
  @ViewChild('retryPaymentOverlay') retryPaymentOverlay: OverlayPanel;
  earliestFailedPayment: PolicyPaymentDetails;
  creditCardPaymentMethod: CreditCardPaymentMethod;
  isVisible: boolean;
  readonly hideFailedPaymentBannerStorageKey = 'hideFailedPaymentBanner';
  ButtonType = ButtonType;
  private infoSub = Subscription.EMPTY;
  private bannerName = 'credit-card-failed-payment-banner';

  constructor(
    private store: Store<AppState>,
    private paymentDataService: PaymentDataService,
    private paymentService: PaymentService,
    private failedPaymentBannerTrackingService: FailedPaymentBannerTrackingService,
    private changeDetectorRef: ChangeDetectorRef,
    private mobileAppEventsService: MobileAppEventsService,
    private toastService: ToastService,
    private bannersTrackingService: BannersTrackingService,
    @Inject(SESSION_STORAGE) private sessionStorage: Storage,
  ) {}

  ngOnInit(): void {
    if (this.isBannerEnabledForTheSession()) {
      this.infoSub = combineLatest([
        this.store.select(paymentSelectors.isLoading),
        this.store.select(policiesSelectors.hasOperativeNextPolicy),
        this.store.select(paymentSelectors.getPaymentMethodDetails),
        this.store.select(paymentSelectors.getPaymentDetailsPerPolicy),
        this.paymentService.shouldDisplayFailedPaymentBannersInCurrURL(),
      ])
        .pipe(
          takeWhile(() => this.isBannerEnabledForTheSession()),
          filter(
            ([isLoadingPayments]: [boolean, boolean, CreditCardPaymentMethod, PaymentDetailsPerPolicy, boolean]) => !isLoadingPayments,
          ),
        )
        .subscribe(
          ([, hasOperativeNextPolicy, paymentMethodDetails, paymentDetailsPerPolicy, shouldBeVisibleInCurrURL]: [
            boolean,
            boolean,
            CreditCardPaymentMethod,
            PaymentDetailsPerPolicy,
            boolean,
          ]) => {
            this.earliestFailedPayment = this.paymentService.getEarliestFailedPayment(paymentDetailsPerPolicy);
            this.creditCardPaymentMethod = paymentMethodDetails;

            if (this.earliestFailedPayment && hasOperativeNextPolicy && shouldBeVisibleInCurrURL) {
              this.changeVisibility(true);
              this.bannersTrackingService.trackViewBanner(this.bannerName, { bannerType: 'DEFAULT' });
            } else {
              if (this.isVisible) {
                this.bannersTrackingService.trackHideBanner(this.bannerName, { bannerType: 'DEFAULT' });
              }
              this.changeVisibility(false);
            }
          },
        );
    }
  }

  ngOnDestroy(): void {
    this.infoSub.unsubscribe();
  }

  onClose(): void {
    this.bannersTrackingService.trackCloseBannerClicked(this.bannerName, { bannerType: 'DEFAULT' });
    this.sessionStorage.setItem(this.hideFailedPaymentBannerStorageKey, '1');
  }

  editCard(): void {
    this.failedPaymentBannerTrackingService.trackCreditCardFailedPaymentBannerClickEditCard('DEFAULT');
    this.paymentService.openUpdatePaymentMethodModal({
      shouldCloseMobileBanner: true,
      onPaymentUpdateFinished: () => this.onPaymentUpdateFinished(),
    });
  }

  retryPayment(event: Event): void {
    this.failedPaymentBannerTrackingService.trackCreditCardFailedPaymentBannerClickRetry('DEFAULT');
    this.retryPaymentOverlay.show(event);
    this.paymentDataService
      .retryPayment()
      .pipe(
        tap(() => {
          this.toastService.showToast({
            toastType: ToastType.Success,
            message: 'PAYMENT.RETRY_PAYMENT.SUCCESS_MESSAGE',
          });
          if (this.mobileAppEventsService.shouldSendPaymentRefreshEvent()) {
            this.mobileAppEventsService.notifyMobile({
              eventId: MobileAppEventId.LastFailedPaymentHandledEvent,
              closeWebview: false,
            });
          }
          this.changeVisibility(false);
          this.retryPaymentOverlay.hide();
        }),
        switchMap(() => this.paymentService.loadCombinedPaymentDetails()),
        catchErrorAndLog(() => {
          this.toastService.showToast({
            toastType: ToastType.Error,
            message: 'PAYMENT.RETRY_PAYMENT.ERROR_MESSAGE',
          });
          this.retryPaymentOverlay.hide();
          return of(null);
        }),
      )
      .subscribe();
  }

  private isBannerEnabledForTheSession(): boolean {
    return !this.sessionStorage.getItem(this.hideFailedPaymentBannerStorageKey);
  }

  private changeVisibility(isVisible: boolean): void {
    this.isVisible = isVisible;
    this.changeDetectorRef.markForCheck();
  }

  private onPaymentUpdateFinished(): void {
    this.toastService.showToast({
      toastType: ToastType.Success,
      message: `PAYMENT.UPDATE_PAYMENT_METHOD.SUCCESS_MESSAGE_AFTER_RETRY`,
    });
  }
}
