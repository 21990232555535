import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';

import { BusinessDataService } from '../../business/business.data.service';
import { BusinessIdService } from '../../business/business-id.service';
import { AccountBusiness } from '../models/account-businesses-response.model';

export const accountBusinessesResolver: ResolveFn<Observable<AccountBusiness[]>> = () => {
  const businessIdService = inject(BusinessIdService);
  const businessDataService = inject(BusinessDataService);
  const businessIds = businessIdService.getAccountBusinessIds();
  return businessDataService.getAccountBusinesses(businessIds);
};
