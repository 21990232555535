<ni-alert-banner *ngIf="isVisible" (closed)="onClose()" data-test="credit-card-failed-payment-banner">
  <div banner-body class="banner-body" data-test="failed-payment-banner-body">
    <span>{{
      'FAILED_PAYMENT_BANNER.CREDIT_CARD.DEFAULT'
        | translate
          : {
              cardEndingDigits: creditCardPaymentMethod.creditCardDetails.cardEndingDigits,
              date: earliestFailedPayment.cardData.firstFailedPaymentInLastSequenceOfFailures.date | date: 'M/d/yy',
            }
    }}</span>
  </div>
  <div banner-actions class="banner-actions">
    <ni-button-rb (click)="editCard()" [buttonType]="ButtonType.Tertiary" data-test="edit-card-button">
      {{ 'FAILED_PAYMENT_BANNER.CREDIT_CARD.UPDATE_CARD' | translate }}
    </ni-button-rb>
    <ni-button-rb (click)="retryPayment($event)" [buttonType]="ButtonType.Tertiary" data-test="retry-payment-button">
      {{ 'FAILED_PAYMENT_BANNER.CREDIT_CARD.RETRY_PAYMENT' | translate }}
    </ni-button-rb>
  </div>
</ni-alert-banner>

<p-overlayPanel #retryPaymentOverlay styleClass="ni-overlay-panel full-screen">
  <div class="retry-payment-overlay">
    <div class="title">{{ 'PAYMENT.RETRY_PAYMENT.TITLE' | translate }}</div>
  </div>
</p-overlayPanel>
