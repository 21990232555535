import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import logger from '@next-insurance/logger';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BusinessDataService } from '../../business/business.data.service';
import { BusinessIdService } from '../../business/business-id.service';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppUrl } from '../models/app-url.enum';

export const accountBusinessesGuard: CanActivateFn = (): Observable<boolean> => {
  const businessDataService = inject(BusinessDataService);
  const router = inject(Router);
  const businessIdService = inject(BusinessIdService);
  const businessIds = businessIdService.getAccountBusinessIds();
  if (businessIds.length < 2) {
    logger.error('Account has only one business, redirecting to home page');
    router.navigateByUrl(AppUrl.Home);
    return of(false);
  }

  return businessDataService.shouldShowBusinessSelectionPage().pipe(
    map((shouldShowBusinessSelectionPage) => {
      if (!shouldShowBusinessSelectionPage) {
        router.navigateByUrl(AppUrl.Home);
      }

      return shouldShowBusinessSelectionPage;
    }),
    catchErrorAndLog(() => {
      router.navigateByUrl(AppUrl.Home);
      return of(false);
    }),
  );
};
